import { apiFetch_card } from '@/resource'
import { ccIsVaild } from '@/util/cc-validate'
import Mixin from '@/mixins/page/deposit'

export default {
  mixins: [Mixin],
  data() {
    const re = /^[0-9\s]*$/
    const reg = /^\d{4}([ \-]?)(\d{4}\1?\d{4}\1?\d{4})+$/

    const onlyNumber = value => {
      value
        .replace(/[0-9]/g, '')
        .replace(/[\s]/g, '')
        .split('')
        .map(m => {
          value = value.replace(m, '')
        })
      return value
    }

    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency,
            })
          )
        )
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        )
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: this.minLimitByUSC,
              currency: this.accountCurrency,
            })
          )
        )
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' })))
      } else {
        callback()
      }
    }

    const valiCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.creditCard = onlyNumber(value)
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else {
        this.form.creditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const valiMasterCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.masterCreditCard = onlyNumber(value)
      const creditCardType = ccIsVaild(value)
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else if (creditCardType !== 'master') {
        callback(new Error(this.$t('common.formValidation.masterCardReq')))
      } else {
        this.form.masterCreditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const valiMasterAmexJCBCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.masterAmexJCBCreditCard = onlyNumber(value)
      const creditCardType = ccIsVaild(value)
      if (creditCardType !== 'master' && creditCardType !== 'jcb' && creditCardType !== 'amex') {
        callback(new Error(this.$t('common.formValidation.masterCardAmexJCBReq')))
      } else {
        this.form.masterAmexJCBCreditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const valiMasterJCBCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.masterJCBCreditCard = onlyNumber(value)
      const creditCardType = ccIsVaild(value)
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else if (creditCardType !== 'master' && creditCardType !== 'jcb') {
        callback(new Error(this.$t('common.formValidation.masterCardJCBReq')))
      } else {
        this.form.masterJCBCreditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const valiMasterVisaCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.masterVisaCreditCard = onlyNumber(value)
      const creditCardType = ccIsVaild(value)
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else if (creditCardType !== 'master' && creditCardType !== 'visa') {
        callback(new Error(this.$t('common.formValidation.masterCardVisaReq')))
      } else {
        this.form.masterVisaCreditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const valiCreditCardMiddle = (rule, value, callback) => {
      let reg
      if (rule.max == 6) reg = /^[0-9]{6}$/
      if (rule.max == 7) reg = /^[0-9]{7}$/
      if (!reg.test(value)) {
        callback(this.$t('common.formValidation.mid6Digits', { number: rule.max }))
      } else callback()
    }

    const valiSecurityCode = (rule, value, callback) => {
      const reg = /^[0-9]{3,4}$/
      if (!reg.test(value)) {
        this.form.securityCode = value.toString().replace(/[^\d]/g, '')
        callback(new Error(this.$t('common.formValidation.digits3or4')))
      } else callback()
    }
    return {
      form: {
        accountNumber: '',
        amount: '',
        nameCard: '',
        creditCard: '',
        masterCreditCard: '',
        masterAmexJCBCreditCard: '',
        masterJCBCreditCard: '',
        masterVisaCreditCard: '',
        month: '',
        year: '',
        securityCode: '',
        is3DS: false,
        oldCreditCardFirst: '',
        oldCreditCardMiddle: '',
        oldCreditCardLast: '',
        bankCode: '',
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
        nameCard: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur',
          },
        ],
        creditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiCreditCard,
            trigger: 'blur',
          },
        ],
        masterCreditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiMasterCreditCard,
            trigger: 'blur',
          },
        ],
        masterAmexJCBCreditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiMasterAmexJCBCreditCard,
            trigger: 'blur',
          },
        ],
        masterJCBCreditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiMasterJCBCreditCard,
            trigger: 'blur',
          },
        ],
        masterVisaCreditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiMasterVisaCreditCard,
            trigger: 'blur',
          },
        ],
        month: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change',
          },
        ],
        year: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change',
          },
        ],
        securityCode: [
          {
            required: true,
            message: this.$t('common.formValidation.cvvReq'),
            trigger: 'blur',
          },
          {
            validator: valiSecurityCode,
            trigger: 'blur',
          },
        ],
        bankCode: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCodeReq'),
            trigger: 'blur',
          },
        ],
        oldCreditCardMiddle: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: valiCreditCardMiddle,
            max: 5,
            trigger: 'blur',
          },
        ],
      },
      card: { cardOptions: [], selected: '' },
      bankHTML: '',
    }
  },
  watch: {
    'form.accountNumber'(number) {
      if (number) this.fetchCard()
    },
    'card.selected'(cardNumber) {
      const _card = this.card.cardOptions.find(f => f.id === cardNumber)
      this.form.oldCreditCardMiddle = ''
      if (_card) {
        if (this.regexFourDigit(_card.card_last_four_digits)) {
          this.rules.oldCreditCardMiddle[1].max = 6
        } else {
          this.rules.oldCreditCardMiddle[1].max = 7
        }
        this.form.oldCreditCardFirst = _card.card_begin_six_digits
        this.form.oldCreditCardLast = _card.card_last_four_digits
        this.form.nameCard = _card.card_holder_name
        this.form.month = _card.expiry_month.toString()
        this.form.year = _card.expiry_year.toString()
        this.form.is3DS = _card.three_domain_secure == 1 ? true : false
      } else {
        this.form.oldCreditCardFirst = ''
        this.form.oldCreditCardLast = ''
        this.form.nameCard = ''
        this.form.month = ''
        this.form.year = ''
        this.form.is3DS = false
      }
      this.$refs['form'].validateField('oldCreditCardMiddle')
    },
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber
    },
    fetchCard() {
      apiFetch_card({ mt4Account: this.form.accountNumber }).then(resp => {
        if (resp.data.code == 0) {
          this.card = {
            cardOptions: resp.data.data.cards || [],
            selected: resp.data.data.cards[0].id || '',
          }
        }
      })
    },
    setCardLabel(card) {
      if (this.regexFourDigit(card.card_last_four_digits))
        return card.card_begin_six_digits + '-xxxxxxx-' + card.card_last_four_digits
      else return card.card_begin_six_digits + '-xxxxxx-' + card.card_last_four_digits
    },
    regexFourDigit(number) {
      const reg = /^\d{4}$/
      return reg.test(number)
    },
    errorHandler() {
      this.formFlag = false
      this.successFlag = true
      this.icon_show_fail = true
      this.resultMessage = this.$t('deposit.cc.result.error', {
        email: this.GLOBAL_CONTACT_EMAIL,
      })
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                if (result.data.msg == 'success') {
                  this.formFlag = false
                  this.successFlag = true
                  this.icon_show_success = true
                  this.resultMessage = this.$t('deposit.cc.result.depositSuccess')
                  var mig = result.data.data[2]
                  /** 判斷是否顯示信用卡 */
                  if (!this.form.oldCreditCardFirst && !this.form.is3DS && mig == 'false') {
                    this.hideUpload = false
                    this.userId = result.data.data[0]
                    this.paymentDepositId = result.data.data[1]
                  }
                } else if (result.data.msg == 'fail') {
                  this.formFlag = false
                  this.successFlag = true
                  this.icon_show_fail = true
                  this.resultMessage = this.$t('deposit.default.failed', {
                    platform: this.$config.info.fullName,
                    email: this.GLOBAL_CONTACT_EMAIL,
                  })
                } else {
                  this.successMethod(result)
                }
              } else if (result.data.code == 300) {
                this.errorHandler()
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
              this.$message({
                message: this.$t('deposit.default.failed'),
                type: 'error',
              })
            })
        } else return false
      })
    },
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode
    },
  },
}
